<template>
    <div class="contact-content">
        <v-container id="intro" class="cyan text-center white--text mb-7" fluid>
            <v-row class="justify-center" no-gutters>
                <v-col cols="12" md="6">
                    <h1 class="mb-5"  :class="$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-3'">Du suchst eine Webdesigerin in Vorarlberg?</h1>
                    <p class="font-family-body font-weight-regular"  :class="$vuetify.breakpoint.smAndDown ? 'subtitle-1' : 'title'">
                        Auch wenn du nicht aus Vorarlberg bist – schreib mir oder buche gleich einen Termin für ein Erstgespräch. Ich freue mich über deine Nachricht und erstelle dir gerne ein unverbindliches Angebot.
                    </p>
                </v-col>
            </v-row>
        </v-container>
        <v-container>
            <v-row>
                <v-col cols="12">
                    <v-card class="patterned" color="purple darken-1" elevation="0" tile>
                        <v-responsive  :aspect-ratio="$vuetify.breakpoint.xs ? 2/1 : 3/1" class="align-center">
                            <!--<v-card-text class="text-center white--text">
                                <h2 class="subtitle-2 text-uppercase">Sommerurlaub</h2>
                                <h3 class="mb-5" :class="$vuetify.breakpoint.mdAndDown ? 'display-1' : 'display-2'">Sommerzeit ist Urlaubszeit</h3>
                                <p>Ich nutze den Sommer um durch die Vorarlberger Alpen zu wandern und mich zu erholen.</p>
                                <p>Ab September 2024 bin ich wieder für euch da.</p>
                            </v-card-text>-->
                            <v-card-text class="text-center white--text">
                                <h2 class="subtitle-2 text-uppercase">Neukunden</h2>
                                <h3 class="mb-5" :class="$vuetify.breakpoint.mdAndDown ? 'display-1' : 'display-2'">Projektstart 2025</h3>
                                <p class="text-large">Liebe Stammkunden, danke für eure Treue. Ihr beschäftigt mich den Rest des Jahres mit spannenden Aufgaben.</p>
                                <p class="text-large">Für Neukunden oder größere Projekte habe ich ab 2025 wieder Kapazitäten.</p>
                            </v-card-text>
                        </v-responsive>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-container class="mb-7" fluid>
            <v-row :align="$vuetify.breakpoint.lgAndDown ? '' : 'center'">
                <v-col cols="12" md="6" xl="3">
                    <FlipCard>
                        <template slot="front">
                            <v-card color="lime darken-1" elevation="0" tile>
                                <v-img
                                    alt="Die Webmacherin mit Katze als Illustration - Illustratorin: Mona Nagel"
                                    aspect-ratio="1"
                                    :src="require('@/assets/images/avatar.svg')"
                                ></v-img>
                            </v-card>
                        </template>
                        <template slot="back">
                            <v-card color="lime darken-1" elevation="0" tile>
                                <v-img
                                    alt="Die Webmacherin mit Katze als Foto"
                                    aspect-ratio="1"
                                    :src="require('@/assets/images/team/portrait-sara-katze.webp')"
                                ></v-img>
                            </v-card>
                        </template>
                    </FlipCard>
                </v-col>
                <v-col cols="12" md="6" xl="3">
                    <v-card color="lime darken-1" elevation="0" tile>
                        <v-responsive aspect-ratio="1" class="align-center">
                            <v-card-text class="text-center white--text">
                                <h2 class="subtitle-2 text-uppercase">Die Webmacherin</h2>
                                <h3 :class="$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2'" class=" mb-5">Kontaktdaten</h3>
                                <p><strong>Sara Geller</strong></p>
                                <p>
                                    hallo@die-webmacherin.at<br>
                                    +43 (0)677 61 3344 87
                                </p>
                                <p>
                                    <strong>Telefonzeiten:</strong><br>
                                    Montag – Freitag<br>
                                    10:00 – 18:00
                                </p>
                            </v-card-text>
                        </v-responsive>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6" xl="3">
                    <v-card color="lime darken-1" elevation="0" tile>
                        <v-responsive aspect-ratio="1" class="align-center">
                            <v-card-text class="text-center white--text">
                                <p class="subtitle-2 text-uppercase mb-5">
                                    Erstgespräch buchen:
                                </p>
                                <a href="https://my.meetergo.com/sara-geller/erstgespraech" target="_blank">
                                    <v-img
                                        alt="Erstgespräch buchen (Meetergo)"
                                        contain
                                        max-height="200"
                                        :src="require('@/assets/images/meetergo_erstgespräch-buchen.png')"
                                    ></v-img>
                                </a>
                            </v-card-text>
                        </v-responsive>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6" xl="3">
                    <v-card color="lime darken-1" elevation="0" tile>
                        <v-responsive aspect-ratio="1" class="align-center">
                            <v-card-text class="text-center white--text">
                                <p class="subtitle-2 text-uppercase mb-5">
                                    Digitale Visitenkarte:
                                </p>
                                <a href="https://my.meetergo.com/sara-geller" target="_blank">
                                    <v-img
                                        alt="Digitale Visitenkarte"
                                        contain
                                        max-height="200"
                                        :src="require('@/assets/images/meetergo_digitale-visitenkarte.png')"
                                    ></v-img>
                                </a>
                            </v-card-text>
                        </v-responsive>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<style lang="scss">

</style>

<script>
import FlipCard from '../plugins/FlipCard.vue';

export default {
    name: "Contact",
    components: {
        FlipCard,
    },
};
</script>
